
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/auth/setup",
      function () {
        return require("private-next-pages/auth/setup/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/auth/setup"])
      });
    }
  